import React from "react"
import Slider from "react-slick"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faArrowRight } from "@fortawesome/pro-regular-svg-icons/faArrowRight"
import { faArrowLeft } from "@fortawesome/pro-regular-svg-icons/faArrowLeft"

const CenteredCarousel = ({ children }) => {
  function SlideButton(props) {
    const { prev, next, className, style, onClick } = props
    return (
      <div className={`${className} centered-carousel__btn`} onClick={onClick}>
        <FontAwesomeIcon icon={next ? faArrowRight : faArrowLeft} />
      </div>
    )
  }

  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    slidesToShow: 3,
    centerPadding: "0",
    speed: 500,
    nextArrow: <SlideButton next />,
    prevArrow: <SlideButton prev />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          centerPadding: "30px",
          centerMode: true,
          prevArrow: <></>,
          nextArrow: <></>
        }
      }
    ]
  }
  return (
    <div className="centered-carousel">
      <Slider {...settings}>
        {children.map(child => (
          <div key={child.key} className="slide-wrapper">
            {child}
          </div>
        ))}
      </Slider>
    </div>
  )
}

export default CenteredCarousel
