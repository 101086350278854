import React from "react"
import { Columns, Section } from "../../components/Containers"
import { Image, Text } from "../../components/Core"

function OurMission({ content, image }) {
  return (
    <Section className="our-mission" zeroTop zeroBottom smallSpacing>
      <Columns sideColumnsSize={1} className="our-mission__inner is-vcentered">
        <div
          className="column is-one-third px-40--mobile"
          >
          <Image publicId={image} />
        </div>
        <div className="column is-1"></div>
        <div className="px-40--mobile column">
          <Text className="color-blue-dark mb-1" as="h2" text="Our Mission" />
          <Text className="our-mission__paragraph" text={content} />
        </div>
      </Columns>
    </Section>
  )
}

export default OurMission
